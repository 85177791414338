<template>
  <div class="home-page-vessels">
    <TopNavVue />
    <div class="header">
      <div class="header-vessels">
        <div v-if="inIntroduce">
          <div
            @click="
              $router.push({
                name: 'UserCheckMsg'
              })
            ">
            查看公开信件
          </div>
          <div
            v-if="
              String($store.state.token) !== 'undefined' &&
              String($store.state.token) !== 'null' &&
              String($store.state.userMsg.role) === '1'
            "
            @click="system">
            返回后台
          </div>
          <div
            v-if="
              String($store.state.token) !== 'undefined' && String($store.state.token) !== 'null'
            "
            @click="exit">
            退出登录
          </div>
          <div v-else-if="$route.name !== 'LoginPage' && $route.name !== 'SignPage'" @click="login">登录/注册</div>
          <div
            v-if="
              String($store.state.token) !== 'undefined' && String($store.state.token) !== 'null'
            "
            @click="myMessage">
            我的信息
          </div>
        </div>
        <div v-else>
          <div @click="toIntroduce">我要留言</div>
        </div>
      </div>
    </div>
    <div class="big-vessels">
      <div class="bottom-vessels">
        <div id="homepage">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!-- <div class="user-message">
    <div v-if="String($store.state.token)!=='undefined'&&String($store.state.token)!=='null'&&String($store.state.userMsg.role)==='1'" @click="system">返回后台</div>
    <div v-if="String($store.state.token)!=='undefined'&&String($store.state.token)!=='null'" @click="exit">退出登录</div>
    <div v-else @click="login">登录/注册</div>
    <div v-if="String($store.state.token)!=='undefined'&&String($store.state.token)!=='null'" @click="myMessage">我的信息</div>
  </div> -->
  </div>
</template>

<script>
// import ArticleContentVue from '../pages/HomePage/ArticleContent.vue';
import TopNavVue from '../pages/HomePage/TopNav.vue';
export default {
  name: 'HomePage',
  data() {
    return {
      inIntroduce: false
    };
  },
  // mounted() {
  //   console.log(this.$store.state.token);
  //   this.inIntroduce = this.$route.pat.slice('/')[2] !== 'UserCheckMsg';
  //   console.log(this.$route.path);
  // },
  watch: {
    $route: {
      handler() {
        this.inIntroduce = this.$route.name !== 'UserCheckMsg';
        console.log(this.$route.name);
      },
      immediate: true
    }
  },
  methods: {
    toIntroduce() {
      this.inIntroduce = true;
      this.$router.push({
        name: 'IntroducePage'
      });
    },
    exit() {
      //退出登录
      this.$confirm('是否退出登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.dispatch('removeMessage', undefined);
          this.$message({
            type: 'success',
            message: '退出登录'
          });
          let _this = this;
          setTimeout(() => {
            _this.$router.push({
              name: 'LoginPage'
            });
          }, 1000);
        })
        .catch(function () {
          return;
        });
    },
    login() {
      console.log(this.$route);
      //登录
      if (this.$route.name !== 'LoginPage') {
        this.$router.push({
          name: 'LoginPage'
        });
      }
    },
    // 个人信息
    myMessage() {
      this.$router.push({
        name: 'UserMessages'
      });
    },
    // 返回后台
    system() {
      this.$router.push({
        name: 'ClassifyManagement'
      });
    }
  },
  components: {
    // ArticleContentVue,
    TopNavVue
  }
};
</script>
<style lang="scss" scoped>
$wh: 80px;
.home-page-vessels {
  display: flex;
  height: 100%;
  flex-direction: column;

  // min-width: 1300px;
  .header {
    margin: 0 auto;
    width: 100%;
    height: 30px;
    background-color: #d13440;
    .header-vessels {
      margin: 0 auto;
      width: 970px;
      max-width: 100%;
      height: 100%;

      @media screen and (max-width: 1300px) {
        width: 820px;
      }

      // background-color: #ffffff;
      div {
        float: right;
        cursor: pointer;
        margin-left: 20px;
        height: 100%;
        line-height: 30px;
        font-weight: 600;
        color: #ffffff;
        transition: all 0.3s;
        &:hover {
          color: #000000;
        }
      }
    }
  }
  .user-message {
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: $wh;
    div {
      cursor: pointer;
      margin-bottom: 30px;
      width: 100%;
      height: $wh;
      font-weight: 600;
      border-radius: 50%;
      text-align: center;
      color: #000000;
      background-image: url("../assets/china.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      line-height: $wh;
    }
  }
  .big-vessels {
    display: flex;
    width: 100%;
    background-color: #d13440;
    flex: 1;
    flex-direction: column;
    .bottom-vessels {
      flex: 1;
      margin: 0 auto;

      // padding-bottom: 30px;
      width: 100%;

      // min-width: 1300px;
      max-width: 1300px;
      height: auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("../assets/mailbox22.png");

      @media screen and (max-width: 1300px) {
        background-image: none;
      }
      #homepage {
        position: relative;
        top: 50%;
        margin: 0 auto;
        padding: 20px;
        padding-right: 50px;
        padding-bottom: 0;
        padding-left: 50px;
        width: 76%;
        max-width: 900px;
        line-height: 2;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
