<template>
<div class="vessels">
  <div id="top-nav">
    <div class="logo con-hidden"></div>
    <div class="school-name"></div>
    <div class="con-logo con-hidden"></div>
    <div class="con-one con-hidden">
      <img src="../../assets/con-two.png" alt="">
    </div>
    <div class="con-two con-hidden">
      <img src="../../assets/con-three.png" alt="">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'TopNav'
};
</script>

<style lang="scss" scoped>
@mixin background {
  background-position: center;
  background-size: cover;
}
.vessels {
  width: 100%;

  // min-width: 1200px;
  height: 145px;

  // background: #9a0000;
  background: #d13440;
  #top-nav {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    height: 150px;
    background: #d13440;

    // background: #9a0000;

    @include background;
    .logo {
      position: absolute;
      top: 35px;
      left: 35px;
      width: 80px;
      height: 80px;
      background-image: url("../../assets/hist_logo.png");

      @include background;
    }
    .school-name {
      position: absolute;
      top: 35px;
      left: 120px;
      width: 320px;
      height: 80px;
      background-image: url("../../assets/redName.png");

      @include background;

      @media screen and (max-width: 1300px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .con-hidden {
      @media screen and (max-width: 1300px) {
        display: none !important;
      }
      img {
        width: 100%;
      }
    }
    .con-logo {
      position: absolute;
      top: 35px;
      right: 150px;
      width: 275px;
      height: 80px;
      background-image: url("../../assets/con-font.png");

      @include background;
    }
    .con-one {
      position: absolute;
      top: 11px;
      right: 343px;
      width: 213px;
      height: 80px;

      // background-image: url("../../assets/con-two.png");

      @include background;
    }
    .con-two {
      position: absolute;
      top: 14px;
      right: -36px;
      width: 198px;
      height: 130px;

      // background-image: url("../../assets/con-three.png");

      @include background;
    }
  }
}
</style>