var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-page-vessels"},[_c('TopNavVue'),_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-vessels"},[(_vm.inIntroduce)?_c('div',[_c('div',{on:{"click":function($event){return _vm.$router.push({
              name: 'UserCheckMsg'
            })}}},[_vm._v(" 查看公开信件 ")]),(
            String(_vm.$store.state.token) !== 'undefined' &&
            String(_vm.$store.state.token) !== 'null' &&
            String(_vm.$store.state.userMsg.role) === '1'
          )?_c('div',{on:{"click":_vm.system}},[_vm._v(" 返回后台 ")]):_vm._e(),(
            String(_vm.$store.state.token) !== 'undefined' && String(_vm.$store.state.token) !== 'null'
          )?_c('div',{on:{"click":_vm.exit}},[_vm._v(" 退出登录 ")]):(_vm.$route.name !== 'LoginPage' && _vm.$route.name !== 'SignPage')?_c('div',{on:{"click":_vm.login}},[_vm._v("登录/注册")]):_vm._e(),(
            String(_vm.$store.state.token) !== 'undefined' && String(_vm.$store.state.token) !== 'null'
          )?_c('div',{on:{"click":_vm.myMessage}},[_vm._v(" 我的信息 ")]):_vm._e()]):_c('div',[_c('div',{on:{"click":_vm.toIntroduce}},[_vm._v("我要留言")])])])]),_c('div',{staticClass:"big-vessels"},[_c('div',{staticClass:"bottom-vessels"},[_c('div',{attrs:{"id":"homepage"}},[_c('router-view')],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }